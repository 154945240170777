/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import PageContent from "../components/PageContent";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";

const Page = ({ data }) => {
  const { title, content, seo } = data.wpPage;

  return (
    <Layout seo={seo}>
      <PageWrapper>
        <ContentWrapper>
          <PageContent content={content} />
        </ContentWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
      sidebarMenuSelect {
        sidebarMenus {
          menu
        }
      }
    }
  }
`;
